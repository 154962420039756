import React from "react";
import {
  FaMobileAlt,
  FaEnvelopeOpen,
  FaArrowUp,
  FaMapMarker,
} from "react-icons/fa";
import Logo from "../../images/jinl-logo.png";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer className=" bg-black text-whitesmokecolor p-16 md:p-12" id="footer">
      <div className="flex justify-end">
        <FaArrowUp
          className="text-3xl text-maincolor cursor-pointer mb-4"
          onClick={scrollToTop}
        />
      </div>
      <div className="flex sm:flex-col gap-6 items-center justify-center">
        <div className="flex-1 flex flex-col items-center justify-center  p-2 rounded-md">
          <img src={Logo} alt="logo" className="w-[150px]" />
          <p className="text-maincolor font-bold text-xs">RC No.: 7160194</p>
        </div>

        <div className="flex-1 gap-y-6 md:text-center md:items-center">
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Location:
            </h2>
            <div>
              <div>
                <p className="flex gap-1 items-center">
                  <FaMapMarker className="text-seccolor" /> CB Finance House,
                  16E Ahmadu Bello Way. Kaduna State. , Nigeria
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Email:
            </h2>
            <p className="flex gap-1 items-center">
              <FaEnvelopeOpen className="text-seccolor" />
              info@janibaintercontinentalnetwork.com
            </p>
          </div>
        </div>
        <div className="flex-1">
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Phone:
            </h2>
            <p className="flex gap-1 items-center">
              <FaMobileAlt className="text-seccolor" /> +234 (818) 705 0789
            </p>
          </div>
        </div>
      </div>
      <div className="text-sm lg:mt-4 mt-4 text-center md:text-xs">
        &copy; 2020 - {new Date().getFullYear()} Janiba Intercontinental Network
        Ltd. | All Right Reserved
      </div>
    </footer>
  );
}

export default Footer;
