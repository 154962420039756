import React, { useState } from "react";
import { motion } from "framer-motion";
import Logo from "../../images/jinl-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const CustomLink = ({ to, title, className = "" }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const handleClick = (event) => {
    if (to.startsWith("#")) {
      event.preventDefault();
      const elementId = to.slice(1); // Remove "#" to get the element ID
      const targetElement = document.getElementById(elementId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <a
      href={to}
      onClick={handleClick}
      className={`${className} relative group text-black hover:text-seccolor transition-all duration-500 ease`}
    >
      {title}
      <span
        className={`h-[2px] inline-block bg-gradient-to-r from-seccolor to-maincolor absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-500 ${
          currentPath === to ? "w-full" : "w-0"
        }`}
      >
        &nbsp;
      </span>
    </a>
  );
};

const CustomMobileLink = ({ to, title, className = "", toggle }) => {
  const handleClick = (event) => {
    if (to.startsWith("#")) {
      event.preventDefault();
      const elementId = to.slice(1); // Remove "#" to get the element ID
      const targetElement = document.getElementById(elementId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    toggle();
  };

  return (
    <button
      className={`${className} relative group uppercase`}
      onClick={handleClick}
    >
      {title}
      <span
        className={`h-[2px] inline-block bg-gradient-to-r from-seccolor to-maincolor absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-500`}
      >
        &nbsp;
      </span>
    </button>
  );
};

function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="shadow-lg fixed z-30 w-full bg-gray">
      <div className="bg-purewhite flex justify-between items-center px-48 pt-2 relative xl:px-24 lg:px-16 md:px12 sm:px-8">
        <Link
          to={"/"}
          className="mb-3 flex flex-col justify-center items-center relative"
        >
          <div className="logo flex items-center justify-center gap-2">
            <img src={Logo} alt="Logo" className="w-[80px]" />
            <h1 className="text-2xl font-extrabold">
              Janiba Intercontinental<span className="block">Network Ltd.</span>
            </h1>
          </div>
          {/* <p className="text-seccolor font-bold text-[12px] absolute top-1 left-20">
            RC No.: 7160194
          </p> */}
        </Link>
        <div className="space-x-5 text-gray-400 uppercase lg:hidden">
          <CustomLink to="/" title="Home">
            Home
          </CustomLink>
          <CustomLink to="#about" title="About">
            About us
          </CustomLink>
          <CustomLink to="#core" title="Core Services">
            Core Services
          </CustomLink>
          <CustomLink to="#management" title="Management Team">
            Management Team
          </CustomLink>
          <CustomLink to={"#footer"} title="Contact">
            Contact us
          </CustomLink>
        </div>

        {isOpen ? (
          <motion.div
            className="min-w-[70vw] flex flex-col justify-center items-center z-30 space-y-3 bg-seccolor/90 rounded-lg backdrop-blur-md py-32 px-12 text-purewhite fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 "
            initial={{ scale: 0, opacity: 0, x: "-50%", y: "-50%" }}
            animate={{ scale: 1, opacity: 1, transition: { duration: 1 } }}
          >
            <CustomMobileLink to={"/"} title="Home" toggle={handleClick}>
              Home
            </CustomMobileLink>
            <CustomMobileLink to={"#about"} title="About" toggle={handleClick}>
              About us
            </CustomMobileLink>
            <CustomMobileLink
              to={"#core"}
              title="Core Services"
              toggle={handleClick}
            >
              Core Services
            </CustomMobileLink>
            <CustomMobileLink
              to={"#management"}
              title="Management Team"
              toggle={handleClick}
            >
              Management Team
            </CustomMobileLink>
            {/* <CustomMobileLink
              to={"#portfolio"}
              title="Portfolio"
              toggle={handleClick}
            >
              Portfolio
            </CustomMobileLink> */}
            <CustomMobileLink
              to={"#footer"}
              title="Contact"
              toggle={handleClick}
            >
              Contact us
            </CustomMobileLink>
          </motion.div>
        ) : null}

        <button
          className=" absolute right-8 top-12 flex-col justify-center items-center hidden lg:flex"
          onClick={handleClick}
        >
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${
              isOpen ? "rotate-45 translate-y-1" : " -translate-y-0.5"
            }`}
          ></span>
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 ${
              isOpen ? "opacity-0" : "opacity-100"
            }`}
          ></span>
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${
              isOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"
            }`}
          ></span>
        </button>
      </div>
    </nav>
  );
}

export default Nav;
