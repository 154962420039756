import React from "react";
import { motion } from "framer-motion";
import AgroAlliedImage from "../../images/agro-allied.png";
import PageHeader from "../PageHeader";

const textShow = {
  initial: {
    opacity: 0,
    x: 200,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
};

function AgroAllied() {
  return (
    <div
      className="about-us min-h-[50vh] py-32 sm:py-32 px-32 lg:px-24 md:px-16 sm:px-4 items-center justify-center bg-maincolor text-purewhite"
      id="core"
    >
      <PageHeader
        title={"Core Services"}
        className="bg-purewhite text-maincolor"
      />
      <div className="flex lg:flex-col gap-8 ">
        <div className="about-home flex-1 order-2 relative h-max  rounded-2xl overflow-hidden">
          <div className="absolute top-0 -right-3 -z-10 w-[102%] h-[103%] rounded-2xl bg-black" />
          <img
            src={AgroAlliedImage}
            alt="about"
            className="rounded-2xl w-full h-full hover:scale-150 transition-all duration-300 ease-in-out shadow-2xl"
          />
        </div>
        <div className="h-full flex-1  space-y-6 p-4 text-md overflow-hidden text-lg">
          <h2 className="text-2xl font-extrabold">AGRO-ALLIED SERVICES:</h2>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            Janiba Intercontinental Network Ltd has a deep-rooted connection to
            the agricultural industry, offering comprehensive agro-allied
            services. We collaborate with local farmers and agribusinesses to
            enhance agricultural productivity and promote sustainable farming
            practices.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            Our services encompass crop cultivation, crop management, and market
            access solutions.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            We are committed to driving growth in the agricultural sector by
            ensuring high yields, improved crop quality, and increased
            profitability for our partners.
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default AgroAllied;
