import React from "react";
import { motion } from "framer-motion";
import HomeAboutImage from "../../images/about.png";
import PageHeader from "../PageHeader";

const textShow = {
  initial: {
    opacity: 0,
    x: 200,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
};

function About() {
  return (
    <div
      className="about-us min-h-[50vh] py-32 sm:py-32 px-32 lg:px-24 md:px-16 sm:px-4 items-center justify-center bg-purewhite text-black"
      id="about"
    >
      <PageHeader title={"About JINL"} className="text-purewhite" />
      <div className="flex lg:flex-col gap-8 ">
        <div className="about-home flex-1 relative h-max  rounded-2xl overflow-hidden">
          <div className="absolute top-0 -right-3 -z-10 w-[102%] h-[103%] rounded-2xl bg-black" />
          <img
            src={HomeAboutImage}
            alt="about"
            className="rounded-2xl w-full h-full hover:scale-150 transition-all duration-300 ease-in-out"
          />
        </div>
        <div className="h-full flex-1  space-y-6 p-4 text-md overflow-hidden text-lg">
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            Janiba Intercontinental Network Ltd is a dynamic and versatile
            multiservice company dedicated to delivering a diverse array of
            services to meet the ever-evolving needs of its clients.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            With a steadfast commitment to quality, innovation, and customer
            satisfaction, the company specializes in agro-allied services,
            telecommunication solutions, and the exportation of locally produced
            goods.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            Janiba Intercontinental Network Ltd is closely affiliated with
            Janiba Ginger Limited, a specialized entity focusing solely on the
            production, processing, and exportation of ginger.
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default About;
