import React from "react";
import Hero from "../components/hero/Hero";
import About from "../components/home/About";
// import WhyChooseUS from "../components/home/WhyChooseUS";
import ManagementTeam from "../components/home/ManagementTeam";
import HeroSlicks from "../components/HeroSlicks";
import AgroAllied from "../components/home/AgroAllied";
// import Telecommunication from "../components/home/Telecommunication";
import LocalExport from "../components/home/LocalExport";
import Products from "../components/Products";
// import Post from "../components/home/Post";
// import Portfolio from "../components/Portfolio";

function index() {
  return (
    <div className="overflow-hidden">
      <Hero />
      <HeroSlicks />
      <About />
      <AgroAllied />
      {/* <Telecommunication /> */}
      <LocalExport />
      <Products />
      <ManagementTeam />
    </div>
  );
}

export default index;
