import React, { useState } from "react";
import { motion } from "framer-motion";
import PageHeader from "./PageHeader";
import BGVideos from "../videos/bags-of-ginger.mp4";
import { Link, useNavigate } from "react-router-dom";

function Products() {
  const navigate = useNavigate();

  const handleScroll = () => {
    navigate("/"); // Navigate to the current page if not already on it
    const section = document.getElementById("footer");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative h-screen w-full overflow-hidden bg-maincolor text-purewhite">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src={BGVideos} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay Content */}
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-center text-white bg-black/50 p-4">
        <h1 className="text-4xl md:text-6xl sm:text-3xl font-bold mb-4">
          Janiba Intercontinental Network Ltd.
        </h1>
        <p className="text-lg sm:text-sm md:text-xl mb-6 max-w-3xl leading-relaxed">
          Pioneering the export of Nigeria’s finest{" "}
          <span className="font-bold">agro-allied products</span>, including
          premium <span className="font-bold">ginger</span> and{" "}
          <span className="font-bold">hibiscus</span>, we connect the world to
          the richness of our local produce. At Janiba, we believe in empowering
          local farmers and delivering quality to global markets.
        </p>
        <button
          onClick={handleScroll}
          className="px-6 py-3 bg-maincolor text-white font-bold rounded-md hover:bg-seccolor transition duration-300"
        >
          Contact Us
        </button>
      </div>
    </div>
  );
}

export default Products;
