import React from "react";
import { motion } from "framer-motion";
import LocalExportImage from "../../images/local-exports.jpg";

const textShow = {
  initial: {
    opacity: 0,
    x: 200,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
};

function LocalExport() {
  return (
    <div
      className="about-us min-h-[50vh] py-24 sm:py-32 px-32 lg:px-24 md:px-16 sm:px-4 items-center justify-center bg-purewhite text-black"
      id="about"
    >
      <div className="flex lg:flex-col gap-8 ">
        <div className="about-home flex-1 order-2 relative h-max  rounded-2xl overflow-hidden">
          <div className="absolute top-0 -right-3 -z-10 w-[102%] h-[103%] rounded-2xl bg-black" />
          <img
            src={LocalExportImage}
            alt="about"
            className="rounded-2xl w-full h-full hover:scale-150 transition-all duration-300 ease-in-out"
          />
        </div>
        <div className="h-full flex-1  space-y-6 p-4 text-md overflow-hidden text-lg">
          <h2 className="text-2xl font-extrabold uppercase">
            Exportation of Locally Produced Goods:
          </h2>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            Janiba Intercontinental Network Ltd plays a pivotal role in
            promoting locally produced goods to the global market. We facilitate
            the export of high-quality, locally sourced products, ensuring they
            meet international standards and regulations.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            Our focus extends to a wide range of commodities, including
            agricultural produce, processed foods, and artisanal crafts.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-8"
          >
            Our commitment to excellence in exportation opens doors for local
            businesses to access global markets.
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default LocalExport;
