import React, { useState } from "react";
import { motion } from "framer-motion";
import Jane from "../../images/j-jane.png";
import Tope from "../../images/j-tope.png";
import Mary from "../../images/j-mary.png";
import Paulina from "../../images/j-paulina.png";
import Justina from "../../images/j-justina.png";
import Sheyin from "../../images/j-sheyin.png";
import PageHeader from "../PageHeader";

const teams = [
  {
    id: 1,
    name: "Jane Kowni Lekwot (Mrs)",
    title: "Managing Director",
    content: [
      "She holds a BA (Hons) in Sociology with Law, LLB in Law and an LLM in International Commercial Law. She has been in public service for the for past eleven years and has also been in the Ginger business for the past eleven years, trading domestically with local m erchants an d dealin g in the warehousing of Dried Split Ginger.",
      "She recently started her own ginger company, Janiba Ginger Limited, which is a sister company to Janiba Intercontinental Network Limited, and engages in the production of ginger infused health drinks and pastries for the promotion of health wellness and overall ﬁtness.",
      "A highly motivated and hardworking individual with an ability to meet tight deadlines.",
    ],
    image: Jane,
  },
  {
    id: 2,
    name: "Mr. Tope Kolawole",
    title: "Business Development Manager",
    content: [
      "He holds a Bsc in Business Administration with over 10 years' experience in Digitalisation, Data Science, Blockchain, Agriculture, Mining, Construction and real estate industries. A proactive and solutions-oriented Project-management professional, he has an unﬂinching passion for quality service delivery and is committed to delivering excellent results and effecting positive change.",
    ],
    image: Tope,
  },
  {
    id: 3,
    name: "Mrs Mary C. Balat",
    title: "Management Team Member",
    content: [
      "She holds a B.A (Hons) in Geography and an MBA in Business Administration.",
      "She served in the Civil Service for eleven years and resigned in 1989 to manage the family business, Millennium Department Stores. While in Civil Service she served in various departments including Education.",
      "She has since been involved in Kaduna State politics and is also invested in ginger production. She is a committed, hardworking and driven individual.",
    ],
    image: Mary,
  },
  {
    id: 4,
    name: "Mrs Paulina Balat",
    title: "Management Team Member",
    content: [
      "She holds a BEd in Home Economics and an LLB in Law. She served in the Civil Service for over 30 years and retired as a Director. She worked in various departments such as the Education Department, Teachers Service Board, the Ministry of Environment, the Board of internal Revenue and the Ministry of Works. She has since gone into local Ginger Production and is a very hardworking and committed individual.",
    ],
    image: Paulina,
  },
  {
    id: 5,
    name: "Mrs Justina Bulus",
    title: "Management Team Member",
    content: [
      "She works as a Supervisor in Robert Ginger Company, a foreign company engaged in gin ger expor tation that is based in Kafanchan, Kaduna State.",
      "She over sees the procurement and processing of Dried Split Ginger for the company and has been doing so for the past nineteen years. She is also heavily involved in the cultivation and local trading of the ginger crop.",
      "She has been working with Janiba Interncontinental Network Limited for the past two years as the Manager and Consultant for the local procurement, processing and storage of Dried Split Ginger. She is very committed, hardworking and result oriented.",
    ],
    image: Justina,
  },
  {
    id: 6,
    name: "Mr Sheyin David Ishaku",
    title: "Management Team Member",
    content: [
      "He holds a Bsc in Economics, National Diploma in Banking and Finance and a professional Diploma in Education. He taught at a local primary school for fourteen years and has worked as the Head of Administration for a Microﬁnance Bank called Bakutson, Kafanchan, Kaduna State.",
      "He is currently a supervisor in Olam Nigeria Limited, Kafanchan Branch, Kaduna, a subsidiary of the Olam Group and his roles include the ﬁeld supervision and procurement of Dried Split Ginger for exportation.",
      "He has been in active service for the past nine years and is currently also consulting for Janiba intercontinental Network Limited on ginger export. He is very driven, hardworking and result-oriented.",
    ],
    image: Sheyin,
  },
];

const textShow = {
  initial: {
    opacity: 0,
    x: 200,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
};

function ManagementTeam() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleButtonClick = (item) => {
    setSelectedItem(item);
  };

  const handleClosePopup = () => {
    setSelectedItem(null);
  };
  return (
    <div
      className="about-us min-h-[50vh] py-32 sm:py-24 px-32 lg:px-24 md:px-16 sm:px-4 items-center justify-center bg-gray text-darkgray"
      id="management"
    >
      <PageHeader title={"Management Team"} className="text-purewhite" />

      <div className="grid grid-cols-9 lg:grid-cols-8 gap-6  overflow-hidden">
        {teams.map((item) => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="col-span-3 lg:col-span-4 sm:col-span-8 shadow-lg p-4 bg-purewhite rounded-md"
          >
            <div className="relative flex items-center gap-4">
              <img
                src={item.image}
                alt="item"
                className="w-32 h-32 relative rounded-full border-4 border-maincolor p-2"
              />
              <div>
                <h1 className="text-lg bg-purewhite text-maincolor p-2 rounded-md font-bold">
                  {item.name}
                </h1>
                <p className="font-bol italic text-md px-2 py-1">
                  {item.title}
                </p>
                <button
                  onClick={() => handleButtonClick(item)}
                  className="bg-maincolor text-purewhite py-1 px-2 rounded-md font-bold mt-4"
                >
                  Show Profile
                </button>
              </div>
            </div>

            {selectedItem && (
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purewhite p-5 shadow-lg z-[1000] sm:w-[90%] sm:h-[90%] overflow-hidden">
                <div className="flex sm:flex-col items-center gap-4 h-full relative">
                  {/* Left Section */}
                  <div className="w-1/3 sm:w-full bg-gray text-center p-2 rounded-md">
                    <img
                      src={selectedItem.image}
                      alt="team"
                      className="sm:w-[50%] sm:m-auto border-4 border-maincolor rounded-full object-center p-1 bg-purewhite"
                    />
                    <h2 className="text-lg font-bold">{selectedItem.name}</h2>
                    <p>{selectedItem.title}</p>
                  </div>

                  {/* Right Section with Scrolling */}
                  <div className="w-2/3 sm:w-full h-full overflow-y-auto p-2">
                    <div className="leading-6 text-justify">
                      {selectedItem.content.map((t, index) => (
                        <p key={index} className="mt-4">
                          {t}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Close Button */}
                <button
                  onClick={handleClosePopup}
                  className="bg-maincolor text-purewhite py-1 px-2 rounded-md font-bold mt-4 sm:absolute sm:top-1 sm:right-1"
                >
                  Close
                </button>
              </div>
            )}

            {/* Overlay */}
            {selectedItem && (
              <div
                onClick={handleClosePopup}
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999,
                }}
              ></div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default ManagementTeam;
