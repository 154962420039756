import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick1 from "../images/slick1.png";
import Slick2 from "../images/slick2.png";
import Slick3 from "../images/slick3.png";
import Slick4 from "../images/slick4.png";
import Slick5 from "../images/slick5.png";
import Slick6 from "../images/slick6.png";
import Slick7 from "../images/slick7.png";
import Slick8 from "../images/slick8.png";
import Slick9 from "../images/slick9.png";

function HeroSlicks() {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="slick-slider bg-darkgray">
      <div className="slick-image">
        <img src={Slick1} alt="slideImage" className="border-2 rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick2} alt="slideImage" className="border-2 rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick3} alt="slideImage" className="border-2 rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick4} alt="slideImage" className="border-2 rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick5} alt="slideImage" className="border-2 rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick6} alt="slideImage" className="border-2 rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick7} alt="slideImage" className="border-2 rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick8} alt="slideImage" className="border-2 rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick9} alt="slideImage" className="border-2 rounded-md" />
      </div>
    </Slider>
  );
}

export default HeroSlicks;
